import {
  setPreviousImages,
  updatePreviousImage,
  removePathologiesFromImage,
} from "../../../reducers/previous/previousImagesSlice";

export const getPreviousImages = (image) => async (dispatch) => {
  try {
    await dispatch(setPreviousImages(image));
    return;
  } catch (error) {
    throw error;
  }
};

export const updateLabelsImage = (image) => async (dispatch) => {
  try {
    // console.log('ULI', image)
    await dispatch(updatePreviousImage(image));
    return;
  } catch (error) {
    throw error;
  }
};

// remove pathologies from image
export const removeLabelsImage =
  (contentData) => async (dispatch) => {
    try {
      await dispatch(removePathologiesFromImage(contentData));
      return;
    } catch (error) {
      throw error;
    }
  };
