import { getSliderToggler, toggleSliderLock } from "../../reducers/slider/sliderToggleSlicer";

export const handleSliderToggles = () => (dispatch, getState) => {
  const currentOpenState = getState().slider.open;
  dispatch(getSliderToggler(!currentOpenState));
};

export const handleSliderlock = () => (dispatch, getState) => {
  const currentLockedState = getState().slider.locked;
  dispatch(toggleSliderLock(!currentLockedState));
};


