import axios from "axios";
import { setNextImage, clearNextImage } from "../../../reducers/next/nextSlice";
import { setCurrentImage } from "../../../reducers/images/imagesSlicer";

import { apiRoute } from "../../../../helpers";

export const getNextImage = (
  token, 
  projectId, 
  projectAtLogin
) => async (dispatch, getState) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      projectId,
    },
  };
  try {
    // console.log('ne3', config)
    // console.log('ne3')
    const response = await axios.get(
      `${apiRoute()}/api/v1/training/images/next`,
      config
    );
    // // console.log('projectAtLogin', getState().projects.projectSelectedAtLogin)
    if (!response.data) return null;
    // console.log('clear 4', response.data)
    await dispatch(setNextImage({ image: response.data, projectAtLogin }));
    await dispatch(setCurrentImage(response.data ?? null));
    return response;
  } catch (error) {
    throw error;
  }
};

export const clearCurrentImage = (payload) => async (dispatch) => {
  await dispatch(clearNextImage(payload));
};
