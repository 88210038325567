import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { login } from "../store/actions/authentication";
import { loginService } from "../services/login.service";

import { useNavigate } from "react-router-dom";
import { Spinner, Modal, Button, Row, Col } from "react-bootstrap";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { selectedProject } = useSelector((state) => state.projects);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const credentials = {
      username: event.target.elements.username.value,
      password: event.target.elements.password.value,
    };

    try {
      const response = await dispatch(login(credentials));
      setLoading(false);
      setError(false);
      setErrorMessage("");
      return response;
    } catch (error) {
      setLoading(false);
      setError(true);
      setErrorMessage(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const handleLogin = async () => {
        try {
          navigate("/projects/");
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      handleLogin();
    }
  }, [dispatch, isLoggedIn, navigate, selectedProject]);

  const handleAuthenticateLoginClick = async () => {
    try {
      const redirectUrl = await loginService.authenticateLogin();
      window.location.href = redirectUrl;
    } catch (error) {
      console.error('Failed to redirect:', error);
    }
  };

  return (
    <div>
    <form onSubmit={handleSubmit}>
      <div id="email-group" role="group" className="form-group">
        <input
          id="form_name"
          type="text"
          placeholder="Please enter your email or mobile number"
          required="required"
          aria-required="true"
          className="form-control"
          name="username"
        />
      </div>
      <div id="password-group" role="group" className="form-group">
        <input
          id="form_password"
          type="password"
          name="password"
          placeholder="Please enter your password"
          aria-required="true"
          className="form-control"
          required
        />
      </div>

      {error ? (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      ) : null}

      <Button
        id="Login"
        type="submit"
        disabled={loading}
        className="btn mb-4 px-5 mr-1 rounded-pill font-weight-bold btn-yellow"
      >
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
        ) : null}
        Sign In
      </Button>

      <Button
        id="Register"
        type="button"
        className="btn mb-4 px-5 mx-1 rounded-pill font-weight-bold btn-blue"
        onClick={handleAuthenticateLoginClick}
      >
        3rd Party Login
      </Button>

      <LinkContainer to="/register">
        <Button
          id="Register"
          type="button"
          className="btn mb-4 px-5 ml-1 rounded-pill font-weight-bold btn-yellow"
        >
          Register
        </Button>
      </LinkContainer>
    </form>
    </div>
  );
};

export default Login;
