import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  label: null,
  isLabelLoaded: false,
};

const labelSlice = createSlice({
  name: "label",
  initialState,
  reducers: {
    createLabel: (state, action) => {
      state.label = action.payload;
    },
  },
});

export const { createLabel } = labelSlice.actions;
export default labelSlice.reducer;
