import { apiRoute, handleResponse } from '../helpers';

export const aiLabelService = {
  getAiLabels
};

function getAiLabels(projectId, imageId) {
  const requestOptions = {
    method: 'POST',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}`, 'Content-Type': 'application/json' },
    body: JSON.stringify({
      projectId: projectId,
    })
  };

  return fetch(apiRoute() + `/api/v1/training/labels/${imageId}/ai`, requestOptions).then(handleResponse); 
}
