import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import countReducer from "./count/counterSlice";
import nextImageReducer from "./next/nextSlice";
import imagesSlicer from "./images/imagesSlicer";
import previousImagesSlice from "./previous/previousImagesSlice";
import tagsSlicer from "./tags/tagsSlicer";
import diagnosesSlicer from "./diagnoses/diagnosesSlicer";
import pathologiesSlicer from "./pathologies/pathologiesSlicer";
import projectsSlice from "./projects/projectsSlicer";
import selectorOptionsSlicer from "./selectorOptions/selectorOptionsSlicer";
import sliderToggleSlice from './slider/sliderToggleSlicer';
import windowSlice from './window/windowSlice';
import labelReducer from "./label/labelSlice";
import pathologiesCountReducer from './pathologiesCount/pathologiesCountSlicer';
import { psaReducer } from "./tools/toolsSlicer";

const rootReducer = combineReducers({
  user: userReducer,
  count: countReducer,
  next: nextImageReducer,
  images: imagesSlicer,
  previous: previousImagesSlice,
  tags: tagsSlicer,
  diagnoses: diagnosesSlicer,
  pathologies: pathologiesSlicer,
  projects: projectsSlice,
  selectionOptions: selectorOptionsSlicer,
  slider: sliderToggleSlice,
  window: windowSlice,
  label: labelReducer,
  pathologiesCount: pathologiesCountReducer,
  tools: psaReducer
});

export default rootReducer;

