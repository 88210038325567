/**
 * shows the list of projects when the user logs in
 */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
  selectedProject: null,
  selectedProjectImages: [],
  selectedProjectImagesCount: 0,
  selectedProjectImagesLoaded: false,
  projectSelectedAtLogin: null,
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    getProjects: (state, action) => {
      state.projects = action.payload;
    },

    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload;
      state.selectedProjectId = action.payload;
    },

    setProjectSelectedAtLogin: (state, action) => {
      state.projectSelectedAtLogin = action.payload;
    },
  },
});

export const { getProjects, setSelectedProject, setProjectSelectedAtLogin } = projectsSlice.actions;
export default projectsSlice.reducer;
