import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tags: [],
};

const tagsSlicer = createSlice({
  name: "tags",
  initialState,
  reducers: {
    addTags: (state, action) => {
      state.tags = [...state.tags, ...action.payload];
    },

    removeTags: (state, action) => {
      state.tags = state.tags.filter((tag) => tag !== action.payload);
    },

    updateTags: (state, action) => {
      state.tags = action.payload.filter(tag => tag !== "");
      state.tags.sort((a, b) => a.localeCompare(b));
    },
    
    clearTags: (state, action) => {
      state.tags = [];
    },
  },
});

export const { addTags, removeTags, updateTags, clearTags } =
  tagsSlicer.actions;
export default tagsSlicer.reducer;
