import React from "react";
import { Dropdown } from "react-bootstrap";

const DropdownSelect = ({ selectedOption, onChange, options, disabled, placeholder }) => {
  const primaryOptions = options.filter(option => option.primary);
  const nonPrimaryOptions = options.filter(option => !option.primary);

  const isRejectPlaceholder = placeholder === "Reject";
  const variant = isRejectPlaceholder ? "danger" : "primary";

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="primary"
        id="dropdown-basic"
        className={`mb-5 px-4 ${isRejectPlaceholder ? "btn-danger" : ""}`} // Adjusted for dynamic styling
        disabled={disabled}
      >
        <small
          style={{
            maxWidth: "120px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "0.85rem",
          }}
        >
          {selectedOption.label}
        </small>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {primaryOptions.map((option) => (
          <Dropdown.Item
            key={option._id}
            active={option.label === selectedOption.label}
            onClick={() => onChange(option)}
          >
            {option.label}
          </Dropdown.Item>
        ))}

        {/* Divider (only shown if there are both primary and non-primary options) */}
        {primaryOptions.length > 0 && nonPrimaryOptions.length > 0 && <Dropdown.Divider />}

        {nonPrimaryOptions.map((option) => (
          <Dropdown.Item
            key={option._id}
            active={option.label === selectedOption.label}
            onClick={() => onChange(option)}
          >
            {option.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSelect;