import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoggedIn: false,
  registered: false,
  clientId: null,
  token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setRegistered: (state, action) => {
      state.registered = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
  },
});

export const { setUser, setLoggedIn, setRegistered, setClientId, setToken } =
  userSlice.actions;
export default userSlice.reducer;
