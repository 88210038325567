import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  diagnoses: [],
  diagnosisData: {
    diagnosis: "",
    diagnoses: [],
  },
};

const diagnosesSlicer = createSlice({
  name: "diagnoses",
  initialState,
  reducers: {
    addDiagnoses: (state, action) => {
      state.diagnoses = [...state.diagnoses, ...action.payload];
    },

    removeDiagnoses: (state, action) => {
      state.diagnoses = state.diagnoses.filter((chip) => chip !== action.payload);
    },

    updateDiagnoses: (state, action) => {
      state.diagnoses = action.payload.filter(chip => chip !== "");
      state.diagnoses.sort((a, b) => a.localeCompare(b));
    },
    
    clearDiagnoses: (state, action) => {
      state.diagnoses = [];
    },
  },
});

export const { addDiagnoses, removeDiagnoses, updateDiagnoses, clearDiagnoses } =
  diagnosesSlicer.actions;
export default diagnosesSlicer.reducer;

