import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Authorized from "./components/Authorised";
import RegisterForm from "./components/RegisterForm";
import LoginResponse from "./components/LoginResponse";
import "./assets/styles/custom-main.scss";
import "./assets/styles/styles.scss";
import "./assets/styles/custom.scss";
import "./assets/styles/selector-button.scss";
import "./assets/styles/register-form.scss";
import "./assets/styles/auto-logout.scss";
import "./assets/styles/reset-password.scss";
import "./assets/styles/film-strip.scss";
import AutoLogout from "./components/AutoLogout/AutoLogout";
import ProjectSelector from "./components/ProjectSelector/ProjectSelector";

function App() {
  const { isLoggedIn, user } = useSelector(state => state.user);
  useEffect(()=> {
    if(!isLoggedIn || !user) {
      <Navigate to="/" />
    } 
  }, [isLoggedIn, user]);

  return (
    <Router>
      <AutoLogout />
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <div id="LoginLayout" className="font-size-16 bg-covid">
                <Header />
                <Banner />
              </div>
            }
          />
          <Route path="/projects/" element={isLoggedIn && user ? <ProjectSelector /> : <Navigate to="/" replace />} />
          <Route path="/authorized/projects/:id" element={isLoggedIn && user ? <Authorized /> : <Navigate to="/" replace />} />
          <Route path="/register" element={<RegisterForm />} />
          <Route path="/login/response" element={<LoginResponse />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
