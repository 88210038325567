import React, { useEffect } from "react";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { updateDiagnoses, removeDiagnoses } from "../../store/reducers/diagnoses/diagnosesSlicer";
import styles from "./DiagnosisChip.module.scss";

export default function DropdownWithChip({ selectedDiagnoses }) {
  const dispatch = useDispatch();

  const handleChipDelete = (chipToDelete) => () => {
    dispatch(removeDiagnoses(chipToDelete));
  };

  return selectedDiagnoses.length > 0 ? (
    <div className={styles["diagnosis-chip-container"]}>
      {selectedDiagnoses.map((chip) => (
        <div key={chip} className={styles["diagnosis-chip"]}>
          <Chip
            style={{ color: "#fff" }}
            label={chip}
            onDelete={handleChipDelete(chip)}
            deleteIcon={<CloseIcon style={{ color: "#fff" }} />}
            variant="outlined"
          />
        </div>
      ))}
    </div>
  ) : null;
}
