import { createSlice } from "@reduxjs/toolkit";

const sliderToggleSlice = createSlice({
  name: 'slider',
  initialState: {
    open: false,
    locked: false,
  },
  reducers: {
    getSliderToggler: (state, action) => {
      state.open = action.payload;
    },
    toggleSliderLock: (state, action) => {
      state.locked = action.payload; // Set locked state based on action payload
    },
  },
});

export const { getSliderToggler, toggleSliderLock } = sliderToggleSlice.actions;
export default sliderToggleSlice.reducer;


