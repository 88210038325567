import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const counterSlice = createSlice({
  name: "count",
  initialState,
  reducers: {
    setCount: (state, action) => {
      state.count = action.payload.count;
    },
    decrementCount: (state, action) => {
      state.count = state.count - 1;
    },
  },
});

export const { setCount, decrementCount } = counterSlice.actions;
export default counterSlice.reducer;
