
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  options: {
    tags: [],
    pathologies: [],
    diagnosis: [],
  },
};

const selectorOptionsSlice = createSlice({
  name: "selectorOptions",
  initialState,
  reducers: {
    getSelectorOptions: (state, action) => {
      state.options = action.payload;
    },
  },
});

export const { getSelectorOptions } = selectorOptionsSlice.actions;
export default selectorOptionsSlice.reducer;
