import {
  addTags,
  removeTags,
  updateTags,
  clearTags,
} from "../../reducers/tags/tagsSlicer";

export const addNewTags = (tags) => async (dispatch) => {
  try {
    await dispatch(addTags(tags));
    return;
  } catch (error) {
    throw error;
  }
};

export const removeTag = (tag) => async (dispatch) => {
  try {
    await dispatch(removeTags(tag));
    return;
  } catch (error) {
    throw error;
  }
};

export const updateTagsList = (tags) => async (dispatch) => {
  try {
    await dispatch(updateTags(tags));
    return;
  } catch (error) {
    throw error;
  }
};

export const clearTagsList = () => async (dispatch) => {
  try {
    await dispatch(clearTags());
    return;
  } catch (error) {
    throw error;
  }
};
