import React from 'react';

const ResetPassword = ({ currentPassword, setCurrentPassword, newPassword, setNewPassword, onSubmit, onCancel }) => {
    return (
        <div className="reset-password-container">
            <h5>Reset Password</h5>
            <input
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
            />
            <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
            />
            <div className='flex-btn'>
                <button className="submit-btn" onClick={onSubmit}>
                    Submit
                </button>
                <button className="cancel-btn" onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default ResetPassword;
