import React from 'react'
import Logo from '../assets/img/edai-logo-small.png'

const Header = () => {
  return (
    <div className="header-area">
        <div className="px-sm-5 container-fluid">
          <div className="row">
            <div className="col">
              <img alt='' src={Logo} className='img-fluid mt-3'id="login-logo"/> 
            </div>
            <div className="text-right col align-self-center">
              <a href="https://envisionit.ai" 
                target="_blank" 
                rel="noreferrer" 
                className="btn btn-outline-light btn-box-shadow">
                Main site
              </a>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Header

