import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  previous: [],
  currentImageId: null,
};

const previousImagesSlice = createSlice({
  name: "previous",
  initialState,
  reducers: {
    setPreviousImages: (state, action) => {
      // // console.log("setPreviousImages");
      const image = action.payload;
      // realized that there is some duplicates of the images
      if (!state.previous.some((prevImage) => prevImage.image._id === image.image._id)) {
        state.previous.unshift(image);
      }
    },

    setCurrentImageId: (state, action) => {
      state.currentImageId = action.payload;
    },

    updatePreviousImage(state, action) {
      const { image } = action.payload;
      // console.log("this is the image", action.payload);
      var imageIndex = -1
      for (var i = 0; i<state.previous.length; i++) {
        if (state.previous[i].image._id === image){
          imageIndex = i
          break
        } else if (state.previous[i].image.otherImages && state.previous[i].image.otherImages.length>0){
          var tmp = state.previous[i].image.otherImages.findIndex((_i) => _i._id === image )
          // console.log('tmp', tmp)
          if (tmp >= 0){
            imageIndex = i
            break
          }
        }
      }
      // state.previous.forEach(r => // console.log(r.image._id))? true : i.image.otherImages.findIndex(_i => _i._id === image ) > 0
      // console.log('prev state', imageIndex)
      if (imageIndex === -1) {
        throw new Error(`Image with ID ${image} not found in previous array.`);
      }
      if (state.previous[imageIndex]?.pathologies === undefined) {
        // If pathologies property doesn't exist, create it
        state.previous[imageIndex].pathologies = {};
      }
      state.previous[imageIndex].pathologies = action.payload;
      // // console.log("previous image pathologies", state.previous[imageIndex].pathologies);
    },

    removePathologiesFromImage(state, action) {
      const { image, pathology } = action.payload;

      const imageIndex = state.previous.findIndex((i) => i._id === image._id);
      if (imageIndex === -1) {
        throw new Error(`Image with ID ${image} not found in previous array.`);
      }
      if (state.previous[imageIndex]?.pathologies === undefined) {
        // If pathologies property doesn't exist, create it
        state.previous[imageIndex].pathologies = {};
      }
      const filteredPathologies = state.previous[
        imageIndex
      ].pathologies.pathologies.filter((path) => path.coords.id !== pathology);
      state.previous[imageIndex].pathologies.pathologies = filteredPathologies;
    },
  },
});

export const {
  setPreviousImages,
  setCurrentImageId,
  updatePreviousImage,
  removePathologiesFromImage,
} = previousImagesSlice.actions;
export default previousImagesSlice.reducer;
