import React, { useState, useEffect, memo } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AutoLogout.module.scss";
import { logout } from "../../store/actions/authentication";
import { useDispatch, useSelector } from "react-redux";

const AutoLogout = ({ children }) => {
  const [countdown, setCountdown] = useState(30); // 30 second countdown
  const [showNotification, setShowNotification] = useState(false);
  const loggedIn = useSelector((state) => state.user.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let activityTimer;

    const resetTimer = () => {
      clearTimeout(activityTimer);
      if (loggedIn) {
        activityTimer = setTimeout(() => {
          setShowNotification(true);
        }, 600000); // 10 minutes of inactivity
      }
    };

    const handleActivity = () => {
      setShowNotification(false);
      resetTimer();
    };

    // Add event listeners to reset the timer on user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('scroll', handleActivity);

    // Set the initial timer
    resetTimer();

    // Clean up event listeners and timer on component unmount
    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      clearTimeout(activityTimer);
    };
  }, [loggedIn, dispatch, navigate]);

  useEffect(() => {
    let countdownTimer;
    if (loggedIn && showNotification) {
      countdownTimer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(countdownTimer);
            dispatch(logout());
            navigate("/");
          }
          return prevCountdown - 1;
        });
      }, 1000); // Countdown decreases every second
    }

    return () => {
      clearInterval(countdownTimer);
    };
  }, [loggedIn, showNotification, dispatch, navigate]);

  const handleLogout = async () => {
    await dispatch(logout());
    setShowNotification(false);
    navigate("/");
  };

  return (
    <>
      {loggedIn && showNotification && (
        <div className={styles.autoLogout}>
          You're going to be logged out in {countdown} seconds. Do you want to stay logged in?
          <div className="buttons">
            <button
              type="button"
              className="btn mb-4 px-5 mr-2 rounded-pill font-weight-bold btn-yellow"
              onClick={() => {
                setShowNotification(false);
                setCountdown(30); // Reset countdown
              }}
            >
              Stay Logged In
            </button>
            <button
              className="btn mb-4 px-5 ml-2 rounded-pill font-weight-bold btn-yellow"
              onClick={handleLogout}
            >
              Log Out
            </button>
          </div>
        </div>
      )}
      <div className="auto-logout-wrapper">{children}</div>
    </>
  );
};

export default memo(AutoLogout);
