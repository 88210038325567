// reducer.js

import {  SET_DISPLAY_PATHOLOGIES_COUNT } from './../../actions/pathologiesCount/index';

const initialState = {
  displayPathologiesCount: 0,
};

const pathologyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DISPLAY_PATHOLOGIES_COUNT:
      return {
        ...state,
        displayPathologiesCount: action.payload,
      };
    default:
      return state;
  }
};

export default pathologyReducer;
