import axios from "axios";
import { setCount } from "../../reducers/count/counterSlice";
import { apiRoute } from "../../../helpers";

export const getImageCount = (token, projectId) => async (dispatch) => {
  const config = {
    params: { projectId },
    headers: { Authorization: `Bearer ${token}` },
  };
  try {
    const response = await axios.get(
      `${apiRoute()}/api/v1/training/images/count`,
      config
    );
    dispatch(setCount(response.data));
    return response;
  } catch (error) {
    throw error;
  }
};
