// src/services/auth.service.js

import { apiRoute, handleResponse, jwtHelper, removeUser } from '../helpers'
import { newWindow } from '../store/actions/window';

export const authService = {
  login,
  logout,
  refresh,
  register
}

function login(user, dispatch) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  }

  return fetch(apiRoute() + '/auth/v1/login', requestOptions)
    .then(handleResponse)
    .then(user => {
      // console.log(user);
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user));
      }
      return user;
    });
}

function logout(openWindow, dispatch) {
  if (openWindow) {
    removeUser();
    dispatch(newWindow(false));
  } else {
    removeUser();
  }
}

function refresh() {
  const user = jwtHelper.getUser();
  if (user && user.refreshToken) {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: user.refreshToken })
    };

    return fetch(apiRoute() + '/auth/v1/refresh', requestOptions)
      .then(handleResponse)
      .then(user => {
        if (user.token) {
          localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
      });
  } else {
    return null;
  }
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user)
  };

  return fetch(apiRoute() + '/auth/v1/register', requestOptions).then(handleResponse);
}
