import { apiRoute, handleResponse } from '../helpers'

export const resetPasswordService = async (currentPassword, newPassword) => {
    const response = await fetch(`${apiRoute}/change`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ current: currentPassword, new: newPassword })
    });

    if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || 'Error changing password.');
    }
};

