import { useEffect, useState } from "react";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  Nav,
  Navbar,
} from "react-bootstrap";
import {
  faEnvelope,
  faLock,
  faAddressCard,
  faGraduationCap,
  faIdCardAlt,
  faBuilding,
  faUserTag,
  faMobileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { register } from "../store/actions/authentication";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Logo from "../assets/img/login-logo.svg";
import Reg_1 from "../assets/img/reg-images/reg-1.png";
import Reg_2 from "../assets/img/reg-images/reg-2.png";
import Reg_3 from "../assets/img/reg-images/reg-3.png";
import Reg_4 from "../assets/img/reg-images/reg-4.png";

const RegisterForm = () => {
  const [validName, setValidName] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setStatusMessage] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const credentials = {
      fullname: event.target.elements.fullname.value,
      qualification: event.target.elements.qualification.value,
      password: event.target.elements.password.value,
      hpcsaNumber: event.target.elements.hpcsa.value,
      company: event.target.elements.company.value,
      position: event.target.elements.position.value,
      email: event.target.elements.email.value,
      mobile: event.target.elements.mobile.value,
    };

    try {
      const response = await dispatch(register(credentials));
      setIsSubmitting(false);
      if (response) {
        setStatusMessage({
          status: "success",
          message: "Registration successful. Please login to continue",
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      setStatusMessage({
        status: "error",
        message: error,
      });
      setIsSubmitting(false);
    }
  };

  const handleUserFocus = () => {
    setValidName(true);
  };

  const handleUserBlur = () => {
    setValidName(false);
  };
  return (
    <div>
      <Container
        fluid
        className="bg-light"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Navbar
          className="navbar fixed-top navbar-expand-lg navbar-light bg-light"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <Container>
            <Row className="justify-content-md-center">
              <Col md="auto">
                <Nav>
                  <LinkContainer to="/path" className="navbar-brand no-padding">
                    <img alt="" src={Logo} className="img-fluid" />
                  </LinkContainer>
                </Nav>
              </Col>
            </Row>
          </Container>
        </Navbar>
        <header className="masthead text-white text-right auth">
          <Container fluid>
            <Row className="text-shadow" style={{ paddingTop: "20px" }}>
              <Col xl={1} lg={1} md={1} sm={5} xs={5}></Col>
              <Col xl={3} lg={4} md={5} sm={6} xs={6} className="mx-auto">
                <h1 className="d-none d-sm-none d-md-block d-lg-block d-xl-block text-white">
                  REGIST<span style={{ color: "rgb(254, 202, 63)" }}>ER</span>
                </h1>
                <p
                  className="mb-5 d-none d-sm-none d-md-block d-lg-block d-xl-block"
                  style={{ fontSize: "1.2em", lineHeight: "1.2em" }}
                >
                  Be part of the movement
                  <br />
                  to democratise healthcare
                </p>
              </Col>
              <Col xl={8} lg={7} md={6} sm={1} xs={1}></Col>
            </Row>
          </Container>
        </header>
        <Container className="d-none d-lg-block" style={{ marginTop: "-2rem" }}>
          <Row>
            <Col xl={8} lg={7}></Col>
            <Col xl={3} lg={3} className="d-flex">
              <img alt="" src={Reg_1} />
              <img alt="" src={Reg_2} />
              <img alt="" src={Reg_3} />
              <img alt="" src={Reg_4} />
            </Col>
            <Col xl={1} lg={1}></Col>
          </Row>
        </Container>
        <Container
          style={{
            paddingBottom: "4rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl={2} lg={2} md={1} sm={1} className="col-1"></Col>
              <Col xl={4} lg={4} md={5} sm={5} className="col-5">
                <div id="name-group" role="group" className="form-group">
                  <label>Name and Surname</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faAddressCard} />
                      </div>
                    </div>
                    <input
                      name="fullname"
                      className="form-control"
                      type="text"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your name"
                    />
                  </div>
                </div>
                <div
                  id="qualification-group"
                  role="group"
                  className="form-group"
                >
                  <label>Qualification</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faGraduationCap} />
                      </div>
                    </div>
                    <input
                      name="qualification"
                      className="form-control"
                      type="text"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your qualification"
                    />
                  </div>
                </div>
                <div
                  id="hpcsa-number-group"
                  role="group"
                  className="form-group"
                >
                  <label>HPCSA Number</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faIdCardAlt} />
                      </div>
                    </div>
                    <input
                      name="hpcsa"
                      className="form-control"
                      type="text"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your hpcsa number"
                    />
                  </div>
                </div>
                <div id="company-group" role="group" className="form-group">
                  <label>Company</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faBuilding} />
                      </div>
                    </div>
                    <input
                      name="company"
                      className="form-control"
                      type="text"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your company name"
                    />
                  </div>
                </div>
                <div id="position-group" role="group" className="form-group">
                  <label>Position</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faUserTag} />
                      </div>
                    </div>
                    <input
                      name="position"
                      className="form-control"
                      type="text"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your position"
                    />
                  </div>
                </div>
              </Col>
              <Col xl={4} lg={4} md={5} sm={5} className="col-5">
                <div id="email-group" role="group" class="form-group">
                  <label>Email</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                      </div>
                    </div>
                    <input
                      name="email"
                      className="form-control"
                      type="email"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your email address"
                    />
                  </div>
                </div>
                <div id="mobile-group" role="group" class="form-group">
                  <label>Mobile Number</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faMobileAlt}></FontAwesomeIcon>
                      </div>
                    </div>
                    <input
                      name="mobile"
                      className="form-control"
                      type="text"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your mobile number"
                    />
                  </div>
                </div>
                <div id="password-group" role="group" className="form-group">
                  <label>Password</label>
                  <div className="input-group">
                    <div className="input-group-prepend search">
                      <div className="input-group-text">
                        <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                      </div>
                    </div>
                    <input
                      name="password"
                      className="form-control"
                      type="password"
                      autocomplete="off"
                      required
                      aria-describedby="uidnote"
                      aria-invalid={validName ? "false" : "true"}
                      onFocus={handleUserFocus}
                      onBlur={handleUserBlur}
                      placeholder="Please enter your password"
                    />
                  </div>
                </div>
                <h5>Terms and Conditions</h5>
                <p>
                  By registering an account with Envisionit Deep AI, you are
                  acknowledging that you have read and accepted
                  <a
                    href="https://edai.africa/privacy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    EDAI Terms and Conditions
                  </a>
                  as well as
                  <a
                    href="https://edai.africa/privacy.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    EDAI Privacy Policy
                  </a>
                  .
                </p>
                <div>
                  {message.status === "error" && (
                    <div className="alert alert-danger">{message.message}</div>
                  )}
                  {message.status === "success" && (
                    <div className="alert alert-success">{message.message}</div>
                  )}
                </div>
                <Button
                  type="submit"
                  className="btn mr-2 btn-primary"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Register"
                  )}
                </Button>
                <Button type="reset" className="btn btn-danger" href="/">
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <footer className="footer">
          <div class="text-center">
            © 2019, Envisionit Deep AI (Pty) Ltd. All rights reserved. Terms and
            Conditions apply.
          </div>
        </footer>
      </Container>
    </div>
  );
};

export default RegisterForm;
