import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import styles from "./ProjectList.module.scss";
import cx from "classnames";

const ProjectList = ({ options, handleProjectSelect, className }) => {
  return (
    <div className={styles.dropdownComponent}>
      <div className={cx(styles.projectSelector, className)}>
        <Card className={styles.card}>
          <Card.Body>
            <ListGroup className={styles.projectList}>
              {options.map((project, i) => (
                <ListGroup.Item
                  key={project._id}
                  className={styles.projectListItem}
                  onClick={() => handleProjectSelect(project._id)}
                >
                  <p className={styles.itemTitle}>
                    {i + 1}. {project.name}
                  </p>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ProjectList;
