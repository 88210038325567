import { createSlice } from "@reduxjs/toolkit";

const windowSlice = createSlice({
  name: 'window',
  initialState: {
    openWindow: false,
  },
  reducers: {
    windowToggler: (state, action) => {
      state.openWindow = action.payload;
    },
  },
});

export const { windowToggler } = windowSlice.actions;
export default windowSlice.reducer;