// src/components/LoginResponse.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { authLogin } from '../store/actions/authentication';

const LoginResponse = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const { selectedProject } = useSelector((state) => state.projects);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const queryString = window.location.search.substring(1);
    // console.log('queryString', queryString);

    if (queryString) {
      dispatch(authLogin(queryString))
        .catch(error => {
          console.error('Failed to retrieve token:', error);
        });
    }
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      const handleLogin = async () => {
        try {
          navigate("/projects/");
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
      handleLogin();
    }
  }, [dispatch, isLoggedIn, navigate, selectedProject]);

  return (
    <div>
      <p>Processing login response...</p>
    </div>
  );
};

export default LoginResponse;
