const initialState = {
    psaValues: {}
  };
  
  export const psaReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PSA_VALUE':
        return {
          ...state,
          psaValues: {
            ...state.psaValues,
            [action.payload.index]: action.payload.value,
          }
        };
      default:
        return state;
    }
  };