import {
  addDiagnoses,
  removeDiagnoses,
  updateDiagnoses,
  clearDiagnoses,
} from "../../reducers/diagnoses/diagnosesSlicer";

export const addNewDiagnoses = (diagnoses) => async (dispatch) => {
  try {
    await dispatch(addDiagnoses(diagnoses));
    return;
  } catch (error) {
    throw error;
  }
};

export const removeDiagnosis = (diagnosis) => async (dispatch) => {
  try {
    await dispatch(removeDiagnoses(diagnosis));
    return;
  } catch (error) {
    throw error;
  }
};

export const updateDiagnosesList = (diagnoses) => async (dispatch) => {
  try {
    await dispatch(updateDiagnoses(diagnoses));
    return;
  } catch (error) {
    throw error;
  }
};

export const clearDiagnosesList = () => async (dispatch) => {
  try {
    await dispatch(clearDiagnoses());
    return;
  } catch (error) {
    throw error;
  }
};
