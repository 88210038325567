import { setCurrentImage } from "../../../reducers/images/imagesSlicer";
import { apiRoute } from "../../../../helpers";

/**
 * gets the current Image, also used to get an image by Id.
 */
export const getSelectedImage = (id) => async (dispatch) => {
  try {
    const response = await fetch(
      `${apiRoute()}/api/v1/training/images/abc?projectId=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
    const data = await response.json();
    await dispatch(setCurrentImage(data));
    return data;
  } catch (error) {
    throw error;
  }
};
