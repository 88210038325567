import {
  getProjects,
  setSelectedProject,
} from "../../reducers/projects/projectsSlicer";

export const getUserProjects = (projects) => async (dispatch) => {
  try {
    const response = dispatch(getProjects(projects));
    return response;
  } catch (error) {
    throw error;
  }
};

export const setUserSelectedProject = (project) => async (dispatch) => {
  try {
    const response = dispatch(setSelectedProject(project));
    return response;
  } catch (error) {
    throw error;
  }
};
