import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setDisplayPathologiesCount } from './../store/actions/pathologiesCount'
// import { psaReducer } from '../store/reducers/tools/toolsSlicer';
import { setPsaValue } from '../store/actions/tools';

function Pathologies() {

  const dispatch = useDispatch();
  const [selectedPathologyIndex, setSelectedPathologyIndex] = useState(0);
  const [showMeasurements, setShowMeasurements] = useState(false);
  const [psa, setPsa] = useState({})
  const freehandDrawings = useSelector(
    (state) => state.pathologies.freehandDrawings
  );
  const tools = useSelector(
    (state) => state.selectionOptions.options.tools
  );
  const handleDropdownChange = (event) => {
    setSelectedPathologyIndex(parseInt(event.target.value));
  };

  const toggleMeasurements = () => {
    setShowMeasurements(prevState => !prevState);
  };

  const handlePreviousClick = () => {
    if (selectedPathologyIndex > 0) {
      setSelectedPathologyIndex(selectedPathologyIndex - 2);
    }
  };
  console.log('toolst', tools)
  const PSAcalc = (items, index) => { 
    console.log('freehandDrawings', items)
    console.log('psa', psa)
    if (freehandDrawings &&freehandDrawings.length > 0  && freehandDrawings[0].other && freehandDrawings[0].other.length > 2 && psa[index] && psa[index] !== 0){
      const L = items[0].stats.length * 0.1
      const W = items[1].stats.length * 0.1
      const H = items[2].stats.length * 0.1
      const vol = L * W * H * (Math.PI/6)
      return psa[index] / vol; 
    } else {
      return null
    }
  };
  const PSAVol = (items) => { 
    console.log('freehandDrawings', freehandDrawings)
    if (freehandDrawings &&freehandDrawings.length > 0  && freehandDrawings[0].other && freehandDrawings[0].other.length > 2){
      const L = items[0].stats.length * 0.1
      const W = items[1].stats.length * 0.1
      const H = items[2].stats.length * 0.1
      const vol = L * W * H * (Math.PI/6)
      return  vol; 
    } else {
      return ''
    }
  };
  const handlePsaChange = (index, value, other) => {
    setPsa(prevState => ({
      ...prevState,
      [index]: value, 
    }));
    const pl = {psaVal: value, psaVol: PSAVol(other) , psaDen: PSAcalc(other, index) }
    console.log('psa__', pl)
    dispatch(setPsaValue(index, pl));
  };
  const displayToolPsa = (pathName) => {
    if (tools && tools.psa && tools.psa[pathName] && tools.psa[pathName] === true) {
      return true
    } else {
      return false
    }
  }
  const handleNextClick = () => {
    if (selectedPathologyIndex + 2 < freehandDrawings?.length) {
      setSelectedPathologyIndex(selectedPathologyIndex + 2);
    }
  };

  const displayedPathologies = freehandDrawings?.slice(selectedPathologyIndex, selectedPathologyIndex + 2);

  useEffect(() => {
    const hasMeasurements = displayedPathologies?.some(pathology => pathology?.other?.length > 0);
    if (hasMeasurements) {
      setShowMeasurements(true);
    }
    // console.log('hasMeasurements', hasMeasurements)
    // console.log('showMeasurements', showMeasurements)
  }, []);

  useEffect(() => {
    dispatch(setDisplayPathologiesCount(freehandDrawings?.length || 0));
  }, [freehandDrawings, dispatch]);
  return (
    <div style={{
      background: "#081624", 
      width: "100%", 
      height: "100%", 
      padding: "20px", 
      borderRadius: 7, 
      position: 'relative',
      marginBottom: '20px'
    }}>
      {displayedPathologies?.some(pathology => pathology?.other?.length > 0) &&       
        <div style={{ marginBottom: "20px" }}>
          <button onClick={toggleMeasurements} style={{ float: "left", backgroundColor: 'transparent', border: 'none', color: '#0473b4' }}>
            Measurements {showMeasurements ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
          </button>
        </div>
      }
      <button 
        onClick={handlePreviousClick} 
        style={{ 
          position: 'absolute', 
          left: 0, 
          top: '50%', 
          transform: 'translateY(-50%)', 
          backgroundColor: 'transparent', 
          border: 'none', 
          color: 'darkgray'
        }}
        onMouseEnter={(e) => (e.target.style.color = '#f0b93b', e.target.size = 'lg')}
        onMouseLeave={(e) => (e.target.style.color = 'darkgray', e.target.size = 'sm')}
      >
        <FontAwesomeIcon icon={faArrowLeft} size="sm"/>
      </button>
      <button 
        onClick={handleNextClick} 
        style={{ 
          position: 'absolute', 
          right: 0, 
          top: '50%', 
          transform: 'translateY(-50%)', 
          backgroundColor: 'transparent', 
          border: 'none', 
          color: 'darkgray'
        }}
        onMouseEnter={(e) => (e.target.style.color = '#f0b93b', e.target.size = 'lg')}
        onMouseLeave={(e) => (e.target.style.color = 'darkgray', e.target.size = 'sm')}
      >
        <FontAwesomeIcon icon={faArrowRight} size="sm"/>
      </button>

      {displayedPathologies?.map((item, index) => (
        <div key={index} style={{ width: "96%", margin: 'auto', color: '#0473b4' }}>
          <div style={{ display: "flex", flexDirection: "column", width: '100%', marginBottom: index % 2 === 0 ? '0px' : '0'}}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p className='no-bottom-margin' style={{ color: "#fff", width: '100%', fontSize: '20px', marginBottom: '0 !important' }}>
                {`${selectedPathologyIndex + index + 1} ${item?.text}`} 
              </p>
              <p className='no-bottom-margin' style={{ color: "#fff", fontSize: '14px', marginTop: '6px' }}>
                  {item?.mapped_stats?.xy?.mean !== undefined && <>Mean:{item?.mapped_stats?.xy?.mean?.toFixed(2)}{item?.mapped_stats?.xy?.modalityUnit}</>}
              </p>
            </div>
            <p style={{ fontSize: '14px', color: 'darkgray' }}>{item?.window} Window</p>
            {showMeasurements && item?.other?.length > 0 &&
            (<div style={{ height: "2px", backgroundColor: "#fff", width: '100%' }}></div>)}
            <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
              {item?.other?.map((measurement) => (
                <div key={measurement?.view?.trim()} style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                  {showMeasurements && (
                    <>
                      <h8 style={{ color: "#fff", fontSize: '12px', color: '#0473b4' }}>{measurement?.view?.trim()}</h8>
                      <p style={{ color: "#fff", fontSize: '10px' }}>{measurement?.stats?.length?.toFixed(2)}mm</p>
                    </>
                  )}
                </div>
              ))}
            </div>
            {displayToolPsa(item.text) && item?.other?.length > 2 &&
            (<div> 
              <p  style={{
                  marginBottom: '5px'
                }}> Prostate volume <strong>{PSAVol(item.other).toFixed(4)} </strong>(cc) </p> 

              <label htmlFor="numericInput"  style={{
                  marginBottom: '5px'
                }}>Enter PSA(ng/ml):</label>
              <input id="numericInput" type="number" step="0.0001" value={psa[item.id] || '0'} onChange={(e) => handlePsaChange(item.id, parseFloat(e.target.value), item.other)} style={{
                  marginBottom: '5px'
                }}/>
              <p style={{
                  marginBottom: '5px'
                }}>PSA density <strong>{PSAcalc(item.other, item.id)? PSAcalc(item.other, item.id).toFixed(4) : '0'} </strong>(ng/ml²)</p> 
            </div>)}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Pathologies;
